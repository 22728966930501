import axios from 'axios'
import {
  getDatabase,
  push,
  ref,
  set,
  update,
  onValue
} from 'firebase/database'
import router from '@/router'
import { convertStatus } from '@/utils'

const db = getDatabase()
const dbRef = ref(db, 'messageTransactions')

export default {
  namespaced: true,
  state: () => ({
    data: null,
    incompleteTransactions: null,
    tempData: null
  }),
  getters: {},
  mutations: {
    SET_DATA (state, payload) {
      state.data = payload
    },
    SET_TEMP_DATA (state, payload) {
      state.tempData = payload
      state.incompleteTransactions = payload.filter((it) => it.status !== 'Delivered')
    }
  },
  actions: {
    create ({ dispatch }, data) {
      push(ref(db, 'messageTransactions/'), {
        ...data
      }).then((res) => {
          set(ref(db, 'messageTransactions/' + res.key), {
          ...data,
          id: res.key,
          createdAt: new Date().toISOString(),
          name: data.category + '_' + data.useCase + '_' + res.key
        })

        dispatch('get').then(() => {
          router.push({ name: 'MessageEdit', params: { id: res.key } })
        })
      })
    },
    get ({ commit, rootState }) {
      const accountId = rootState.user.currentUser?.uniqueID || ''

      onValue(dbRef, (snapshot) => {
        const templates = rootState.templates.tempData
        const transactions = Object.values(snapshot.val()).reverse().filter(it => it.accountId === accountId)
        commit('SET_DATA', transactions.filter(it => it && it.id && !it.id.includes('scheduler')))

        if (templates) {
          const mutatedTransactions = transactions.map((transaction) => {
            const template = templates.find(t => t.id === transaction.messageTemplateId)

            return {
              ...transaction,
              category: (template && template.category) || {},
              status: convertStatus(transaction.status),
              useCase: (template && template.useCase) || {}
            }
          })

          commit('SET_TEMP_DATA', mutatedTransactions.filter(it => it && it.id && !it.id.includes('scheduler')))
        }
      })
    },
    async update (_, data) {
      return update(ref(db, 'messageTransactions/' + data.id), {
        ...data
      })
    },
    async send (_, data) {
      const transaction = data
      const auth = {
        username: process.env.VUE_APP_CLICKSEND_email,
        password: process.env.VUE_APP_CLICKSEND_apiKey
      }

      let preview = 'Empty message.'

      if (transaction.paragraphs.length > 0) {
        preview = ''

        transaction.paragraphs.forEach((paragraph, idx) => {
          if (idx === 0) {
            preview += paragraph.preview
          } else {
            preview += '\n\n' + paragraph.preview
          }
        })
      }

      // send SMS
      if (transaction.deliveryMethod === 'SMS') {
        return await axios.post(
          'https://rest.clicksend.com/v3/sms/send',
          {
            messages: [
              {
                'body': preview.replace(/\n/g, '<br>'),
                'to': '+' + transaction.deliveryDetails,
                'from': 'Renz' // No longer than 11 characters and contains no spaces
              }
            ]
          },
          { auth }
        ).then((res) => {
          return res
        })
      }

      // send Email
      if (transaction.deliveryMethod === 'Email') {
        return await axios.post(
          'https://rest.clicksend.com/v3/email/send',
          {
            to : [{
              email : transaction.deliveryDetails,
              name : ''
            }],
            from : {
              email_address_id : '22208',
              name : 'Renz via SendAMessage'
            },
            subject: 'Hello from SendAMessage',
            body: preview.replace(/\n/g, '<br>'),
          },
          { auth }
        ).then((res) => {
          return res
        })
      }

      return null
    }
  }
}
